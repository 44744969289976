/* eslint-disable react/jsx-wrap-multilines */
import { faCircleCheck } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import clsx from 'clsx';
import { isEqual } from 'lodash/fp';
import { Fragment, memo, useMemo, type FC } from 'react';
import { ExpandableCardList } from 'ExpandableContainer';
import { type Section } from './Grades.types';
import { PlaylistBreakdown } from './PlaylistBreakdown';

type CardProps = {
    imageSrc: string;
    title: string;
    sections: Section[];
    started?: boolean;
    completed?: boolean;
};

const classes = {
    container: clsx([
        'tw-flex',
        'tw-justify-between',
        'tw-items-center',
        'tw-h-14',
        'tw-ms-[15px]',
        'tw-me-[15px]',
        'sm:tw-me-[30px]',
        'tw-bg-white',
        'hover:tw-cursor-pointer',
    ]),
    titleBox: clsx(['tw-flex', 'tw-h-[30px]', 'tw-items-center']),
    image: clsx(['tw-h-[30px]', 'tw-w-[30px]', 'tw-rounded-full']),
    title: {
        primary: clsx(['tw-inline-block', 'tw-ms-2.5', 'tw-text-xs', 'min-[365px]:tw-text-base', 'tw-font-semibold']),
        secondary: clsx(['tw-inline-block']),
    },
    checkIcon: clsx([
        'tw-ms-[5px]',
        'tw-h-[14px]',
        'tw-w-[14px]',
        'tw-flex-shrink-0',
        'tw-inline-block',
        'tw-align-[-.125em]',
    ]),
    chevronClasses: clsx([
        'tw-w-[30px]',
        'sm:tw-w-auto',
        'hover:tw-text-blue',
        'tw-transition-transform',
        'tw-duration-[250ms]',
    ]),
    playlistBreakdown: clsx(['tw-transition-opacity', 'tw-duration-[300ms]']),
};

export const PlaylistCard: FC<CardProps> = memo(({ imageSrc, title, sections, completed, started }) => {
    // This allows us to split the last word from the title and combine it with the check icon when we render it
    // which means we can force the last word and the icon to wrap at the same time so we don't have a solo icon wrapped to the next line
    const formattedTitle = useMemo(
        () =>
            completed
                ? title.split(' ').reduce(
                      (prev, curr, i, arr) => {
                          if (i !== arr.length - 1) {
                              prev[0] = prev[0].concat(curr, ' ');
                          } else prev[1] = curr;

                          return prev;
                      },
                      [''],
                  )
                : title,
        [completed, title],
    );

    return (
        <ExpandableCardList
            header={
                <>
                    <img src={imageSrc} alt={title} className={classes.image} />
                    <div
                        className={clsx(
                            classes.title.primary,
                            { 'tw-text-black': started },
                            { 'tw-text-beige-beyond-dark': !started },
                        )}
                    >
                        {Array.isArray(formattedTitle)
                            ? formattedTitle.map((str, i) => {
                                  if (i !== formattedTitle.length - 1) return <Fragment key={str}>{str}</Fragment>;

                                  return (
                                      <div className={classes.title.secondary} key={str}>
                                          {str}
                                          {!!completed && (
                                              <FontAwesomeIcon
                                                  icon={faCircleCheck}
                                                  className={classes.checkIcon}
                                                  color="#0DD037"
                                              />
                                          )}
                                      </div>
                                  );
                              })
                            : title}
                    </div>
                </>
            }
            body={<PlaylistBreakdown sections={sections} />}
        />
    );
}, isEqual);
