/**
 * This component handles file selection and status display for file uploads. It supports
 * drag-and-drop and click-to-select file input methods. It also handles
 * accepting/rejecting files based on file type, optionally allows multiple file
 * selection, displays file status (success, error, or processing) and metadata, and
 * supports passing existing processed files to pre-populate the file list.
 *
 *   Props:
 *     - initialFiles: Array of ImportedFileState objects to pre-populate the file list
 *     - accept: Array of accepted file types (e.g. ['.csv', '.txt'])
 *     - multiple: Flag to allow multiple file selection
 *     - onFileAdded: Called with new files for post-processing, expects a Promise or a fileState back
 *     - onFileRemoved: Called when a file is removed, no return value expected
 *     - onClearFiles: Called when the file list is cleared, no return value expected
 *
 *   Note: All post-selection processing, such as parsing data or storing files, should be
 *         handled by the parent component.
 */

import { useEffect, useRef, useState } from 'react';
import { Box, Typography, Chip } from '@mui/material';
import { useAnimationCompletion } from 'FrontRoyalAngular';
import { AnimatedCloudIcon, ChipContainer, DropzonePaper, DropzoneLabel } from 'FrontRoyalMaterialUi';
import { useDragState } from './hooks/useDragState';
import { useFileProcessing } from './hooks/useFileProcessing';
import { type FileDropzoneProps } from './FrontRoyalMaterialUiForm.types';
import { FileList } from './FileList';

export const FileDropzone: React.FC<FileDropzoneProps> = ({
    initialFiles,
    accept,
    multiple = false,
    onFileAdded,
    onFileRemoved,
    onClearFiles,
    sx,
}) => {
    // File handling
    initialFiles ||= [];
    const { files, isDisabled, handleDrop, handleFileInput, handleFileRemove, handleClearFiles } = useFileProcessing(
        initialFiles,
        multiple,
        accept,
        onFileAdded,
        onFileRemoved,
        onClearFiles,
    );

    // Drag state
    const rootRef = useRef<HTMLElement>(document.documentElement);
    const dropZoneRef = useRef<HTMLDivElement>(null);
    const { isDragging, inDropZone } = useDragState({
        rootRef,
        dropZoneRef,
        onDrop: handleDrop,
        disabled: isDisabled,
    });

    // Animation/hover states
    const [isHovering, setIsHovering] = useState(false);
    const { isAnimating, startAnimation, stopAnimation } = useAnimationCompletion({ duration: 2000 });

    useEffect(() => {
        if ((inDropZone || isHovering) && !isAnimating) {
            startAnimation();
        } else if (!inDropZone && !isHovering && isAnimating) {
            stopAnimation();
        }
    }, [inDropZone, isHovering, isAnimating, startAnimation, stopAnimation]);

    const handleHover = (hovering: boolean) => () => {
        if (isDisabled) return;
        setIsHovering(hovering);
    };

    return (
        <Box sx={{ width: '100%', maxWidth: 500, margin: 'auto', ...sx }}>
            <DropzonePaper
                ref={dropZoneRef}
                elevation={0}
                onMouseEnter={handleHover(true)}
                onMouseLeave={handleHover(false)}
                onClick={() => !isDisabled && document.getElementById('file-dropzone-input')?.click()}
                isDragging={isDragging}
                inDropZone={inDropZone}
                isDisabled={isDisabled}
                isHovering={isHovering}
                data-testid="file-dropzone"
            >
                <AnimatedCloudIcon isAnimating={isAnimating} isDisabled={isDisabled} />
                <Typography variant="subtitle1">
                    {isDisabled ? (
                        'File selected'
                    ) : (
                        <DropzoneLabel component="span" isDragging={isDragging}>
                            Drop files here
                            <span>
                                &nbsp;or&nbsp;
                                <span style={{ color: '#1976d2', textDecoration: 'underline' }}>browse</span>
                            </span>
                        </DropzoneLabel>
                    )}
                </Typography>
                <input
                    id="file-dropzone-input"
                    data-testid="file-dropzone-input"
                    type="file"
                    accept={accept.join(',')}
                    style={{ display: 'none' }}
                    multiple={multiple}
                    onChange={handleFileInput}
                    disabled={isDisabled}
                />
            </DropzonePaper>

            <ChipContainer>
                <Typography variant="subtitle2" color="textSecondary" fontWeight="light">
                    {multiple && 'Multi-select enabled. '}Accepted file types:
                </Typography>
                {accept.map((fileType: string) => (
                    <Chip key={fileType} label={fileType.toLowerCase()} size="small" color="default" />
                ))}
            </ChipContainer>

            <FileList
                files={files}
                onFileRemove={handleFileRemove}
                onClearFiles={handleClearFiles}
                multiple={multiple}
            />
        </Box>
    );
};
export default FileDropzone;
