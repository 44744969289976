import React from 'react';
import { CloudContainer, AnimatedShadow, AnimatedCloudUpload } from './StyledComponents';

interface AnimatedCloudIconProps {
    isAnimating: boolean;
    isDisabled: boolean;
}

export const AnimatedCloudIcon: React.FC<AnimatedCloudIconProps> = ({ isAnimating, isDisabled }) => (
    <CloudContainer>
        <AnimatedShadow isAnimating={isAnimating} />
        <AnimatedCloudUpload isAnimating={isAnimating} isDisabled={isDisabled} />
    </CloudContainer>
);
