import React, { type CSSProperties } from 'react';
import { keyframes, styled, type Theme } from '@mui/material/styles';
import StepConnector, { stepConnectorClasses } from '@mui/material/StepConnector';
import { type StepIconProps } from '@mui/material/StepIcon';
import { Step, StepLabel, Stepper } from '@mui/material';
import { Check } from '@mui/icons-material';

interface QuoralStepIconProps extends StepIconProps {
    icons: { [index: string]: React.ReactElement };
}

interface QuoralStepperStep {
    label: string;
    description?: string;
    icon?: React.ReactElement;
}

interface QuoralStepperProps {
    steps: Record<string, QuoralStepperStep>;
    activeStep: string;
    icons: { [index: string]: React.ReactElement };
    sx?: CSSProperties;
}

const pulseAnimation = (theme: Theme) => keyframes`
  0% {
    box-shadow: 0 0 0 0 ${theme.palette.secondary.main}66;
  }
  50% {
    box-shadow: 0 0 0 10px ${theme.palette.secondary.main}00;
  }
  100% {
    box-shadow: 0 0 0 0 ${theme.palette.secondary.main}00;
  }
`;

const QuoralStepIconRoot = styled('div')<{
    ownerState: { completed?: boolean; active?: boolean };
}>(({ theme, ownerState }) => ({
    backgroundColor: theme.palette.grey[400],
    zIndex: 1,
    color: '#fff',
    width: 40,
    height: 40,
    display: 'flex',
    borderRadius: '50%',
    justifyContent: 'center',
    alignItems: 'center',
    ...(ownerState.active && {
        backgroundColor: theme.palette.secondary.main,
        boxShadow: `0 0 0 0 ${theme.palette.secondary.main}66`,
        animation: `${pulseAnimation(theme)} 2s infinite`,
        '&::before': {
            content: '""',
            position: 'absolute',
            width: 40,
            height: 40,
            borderRadius: '50%',
            backgroundColor: theme.palette.secondary.main,
            zIndex: -1,
            boxShadow: theme.shadows[6],
        },
    }),
    ...(ownerState.completed && {
        backgroundColor: theme.palette.secondary.main,
    }),
}));

const QuoralConnector = styled(StepConnector)(({ theme }) => ({
    [`&.${stepConnectorClasses.alternativeLabel}`]: {
        top: 19,
    },
    [`&.${stepConnectorClasses.active}`]: {
        [`& .${stepConnectorClasses.line}`]: {
            backgroundImage: `linear-gradient(to right, ${theme.palette.secondary.main} 50%, ${theme.palette.grey[300]} 50%)`,
            backgroundSize: '200% 100%',
            backgroundPosition: 'left bottom',
            transition: 'background-position 0.5s ease-out',
        },
    },
    [`&.${stepConnectorClasses.completed}`]: {
        [`& .${stepConnectorClasses.line}`]: {
            backgroundImage: `linear-gradient(to right, ${theme.palette.secondary.main} 50%, ${theme.palette.grey[300]} 50%)`,
            backgroundSize: '200% 100%',
            backgroundPosition: 'left bottom',
            transition: 'background-position 0.5s ease-out',
        },
    },
    [`& .${stepConnectorClasses.line}`]: {
        height: 3,
        border: 0,
        backgroundColor: theme.palette.grey[300],
        borderRadius: 1,
        backgroundImage: `linear-gradient(to right, ${theme.palette.secondary.main} 50%, ${theme.palette.grey[300]} 50%)`,
        backgroundSize: '200% 100%',
        backgroundPosition: 'right bottom',
        transition: 'background-position 0.5s ease-out',
    },
}));

const QuoralStepLabel = styled(StepLabel)(({ theme }) => ({
    '& .MuiStepLabel-label': {
        marginTop: theme.spacing(1),
    },
}));

export const QuoralStepIcon: React.FC<QuoralStepIconProps> = ({ active, completed, className, icon, icons }) => (
    <QuoralStepIconRoot ownerState={{ completed, active }} className={className}>
        {icons[String(icon)]}
    </QuoralStepIconRoot>
);

const stepIconComponent = (props: StepIconProps, icons: { [index: string]: React.ReactElement }) => {
    const iconsCopy = { ...icons };

    // Swap the icon for a checkmark when the step is completed
    iconsCopy[String(props.icon)] = props.completed ? <Check /> : icons[String(props.icon)];

    return <QuoralStepIcon {...props} icons={iconsCopy} />;
};

export const QuoralStepper: React.FC<QuoralStepperProps> = ({ steps, activeStep, icons, sx }) => {
    const stepKeys = Object.keys(steps);

    return (
        <Stepper alternativeLabel activeStep={stepKeys.indexOf(activeStep)} connector={<QuoralConnector />} sx={sx}>
            {stepKeys.map(key => {
                const { label } = steps[key];
                return (
                    <Step key={key} data-testid={`step-${key}`}>
                        <QuoralStepLabel StepIconComponent={props => stepIconComponent(props, icons)}>
                            {label}
                        </QuoralStepLabel>
                    </Step>
                );
            })}
        </Stepper>
    );
};

export default QuoralStepper;
