// iguana service wrapper class
export default angular.module('FrontRoyal.Groups', []).factory('Group', [
    'Iguana',
    Iguana =>
        Iguana.subclass(function () {
            this.setCollection('groups');
            this.alias('Group');
            this.setIdProperty('id');

            this.setCallback('after', 'copyAttrsOnInitialize', function () {
                this.stream_locale_pack_ids = this.stream_locale_pack_ids || [];
            });

            this.extend({
                cachedIndex() {
                    if (!this._cachedIndexPromise) {
                        this._cachedIndexPromise = this.index().then(response => response.result);
                    }

                    return this._cachedIndexPromise;
                },

                clearCache() {
                    this._cachedIndexPromise = undefined;
                },
            });

            Object.defineProperty(this.prototype, 'createdAtDate', {
                get() {
                    return new Date(this.created_at * 1000);
                },
            });

            Object.defineProperty(this.prototype, 'updatedAtDate', {
                get() {
                    return new Date(this.updated_at * 1000);
                },
            });

            Object.defineProperty(this.prototype, 'upperName', {
                get() {
                    return this.name.toUpperCase();
                },
            });

            return {};
        }),
]);
