import { Chip } from '@mui/material';
import { type TaggedSelectOptionTag } from './FrontRoyalMaterialUiForm.types';

type Props = {
    label: string;
    tags: TaggedSelectOptionTag[];
};

export function TaggedSelectOption({ label, tags }: Props) {
    return (
        <>
            {label}
            {tags.map(({ label: tagLabel, color }) => (
                <Chip
                    key={tagLabel}
                    label={tagLabel}
                    color={color}
                    size="small"
                    variant="outlined"
                    style={{ marginLeft: '5px' }}
                />
            ))}
        </>
    );
}

export default TaggedSelectOption;
