import {
    type CircularProgressProps,
    type IconButtonProps,
    IconButton,
    FormControl,
    CircularProgress,
} from '@mui/material';
import { FileDownload as MuiFileDownload, FileDownloadDone as MuiFileDownloadDone } from '@mui/icons-material';
import { useFormContext, Controller } from 'FrontRoyalReactHookForm';
import { green, common } from '@mui/material/colors';

type FileDownloadProps = IconButtonProps & {
    name: string;
    downloading: boolean;
    success?: boolean;
    CircularProgressProps: CircularProgressProps & { 'data-testid'?: string };
};

export const FILE_DOWNLOAD_ERROR_MSG = 'An error occurred. Engineers have been notified';

export const FileDownload = ({
    name,
    downloading,
    success = false,
    CircularProgressProps,
    ...rest
}: FileDownloadProps) => {
    const { control } = useFormContext();
    const buttonSx = {
        ...(success && {
            color: common.white,
            bgcolor: green[500],
        }),
        transition: 'background-color 0.3s, color 0.3s',
    };
    CircularProgressProps = {
        size: '100%',
        sx: {
            position: 'absolute',
            zIndex: 1,
        },
        ...CircularProgressProps,
    };

    return (
        <Controller
            name={name}
            control={control}
            render={() => (
                <FormControl>
                    <IconButton sx={buttonSx} {...rest}>
                        {success ? <MuiFileDownloadDone /> : <MuiFileDownload />}
                    </IconButton>
                    {downloading && <CircularProgress {...CircularProgressProps} />}
                </FormControl>
            )}
        />
    );
};

export default FileDownload;
