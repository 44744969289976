import { useEffect, useState, useCallback } from 'react';
import { useAngularContext } from 'AngularContext';
import './GooglePlaces.scss';
import memoizeOne from 'memoize-one';
import { isEqual } from 'lodash/fp';
import attachGooglePlacesToInput from './attachGooglePlacesToInput';

const memoizeOptions = memoizeOne(options => options, isEqual);

export default function useGooglePlaces({ autoCompleteOptions, onPlaceChanged }) {
    const $injector = useAngularContext();

    const [input, setInput] = useState();

    const inputRef = useCallback(node => {
        let _input;

        if (!node) {
            return;
        }

        // Find the input element that we want to attach google places to
        if (node.nodeName.toLowerCase() === 'input') {
            _input = node;
        } else {
            _input = node.querySelector('input');
        }
        if (!_input) {
            throw new Error('No input element found in referenced DOM element.');
        }

        setInput(_input);
    }, []);

    const [addListener, setAddListener] = useState();
    const _autoCompleteOptions = memoizeOptions(autoCompleteOptions);
    const ConfigFactory = $injector.get('ConfigFactory');
    const chinaRegionMode = ConfigFactory.getSync(true).chinaRegionMode();

    useEffect(() => {
        if (input && !chinaRegionMode) {
            const _addListener = attachGooglePlacesToInput({
                $injector,
                input,
                options: {
                    autoCompleteOptions: _autoCompleteOptions,
                },
            });

            // We have to wrap _addListener in a function.  Otherwise,
            // react will call _addListener() rather than assigning it
            setAddListener(() => _addListener);
        } else {
            setAddListener(null);
        }
    }, [$injector, input, _autoCompleteOptions, chinaRegionMode]);

    useEffect(() => {
        let cancelListener;
        if (addListener) {
            cancelListener = addListener(onPlaceChanged);
        }
        return () => cancelListener && cancelListener();
    }, [addListener, onPlaceChanged]);

    return inputRef;
}
