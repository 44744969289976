import {
    Checkbox as MuiCheckbox,
    FormControl,
    FormControlLabel,
    useTheme,
    type CheckboxProps as MuiCheckboxProps,
} from '@mui/material';
import { useFormContext, Controller } from 'FrontRoyalReactHookForm';
import { memo, type ChangeEvent } from 'react';

type CheckboxProps = MuiCheckboxProps & {
    label: string;
    name: string;
};

const CheckboxComponent = ({ label, name, ...rest }: CheckboxProps) => {
    const { control, watch, setFieldValue } = useFormContext();
    const { palette } = useTheme();

    // We have to do `|| false` instead of `watch(name, false)` because `watch(name, false)` sometimes returns
    // `undefined` and that leads to errors about switching between controlled and uncontrolled components.
    const value = watch(name) || false;
    const onChange = (event: ChangeEvent<HTMLInputElement>) => setFieldValue(name, event.currentTarget.checked);

    return (
        <Controller
            name={name}
            control={control}
            defaultValue={false}
            render={() => (
                <FormControl>
                    <FormControlLabel
                        control={<MuiCheckbox onChange={onChange} {...rest} checked={value} />}
                        style={{ color: palette?.text.primary }}
                        label={label}
                    />
                </FormControl>
            )}
        />
    );
};

const compareFn = (prev: CheckboxProps, next: CheckboxProps) =>
    prev.checked === next.checked && prev.label === next.label && prev.disabled === next.disabled;

export const Checkbox = memo(CheckboxComponent, compareFn) as typeof CheckboxComponent;

export default Checkbox;
