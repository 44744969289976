import { memo } from 'react';
import { useFormContext, Controller } from 'FrontRoyalReactHookForm';
import { type AutocompleteValue, Autocomplete as MuiAutocomplete, TextField as MuiTextField } from '@mui/material';
import { type AutocompleteProps } from './FrontRoyalMaterialUiForm.types';

function AutocompleteComponent<T, M extends boolean>({ name, label, variant, ...rest }: AutocompleteProps<T, M>) {
    const { control } = useFormContext();
    const defaultValue = rest.multiple ? [] : null;

    return (
        <Controller
            control={control}
            name={name}
            render={({ field }) => (
                <MuiAutocomplete
                    {...field}
                    value={(field.value || defaultValue) as AutocompleteValue<T, M, false, false>}
                    {...rest}
                    renderInput={props => <MuiTextField {...props} label={label} variant={variant} />}
                />
            )}
        />
    );
}

export const Autocomplete = memo(AutocompleteComponent) as typeof AutocompleteComponent;

export default Autocomplete;
