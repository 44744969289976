import { keyframes } from '@mui/system';
import { Paper, Box } from '@mui/material';
import { styled } from '@mui/material/styles';
import { CloudUpload } from '@mui/icons-material';

const bobAnimation = keyframes`
    0%, 100% { transform: translateY(0); }
    50% { transform: translateY(-5px); }
`;

const bobShadowAnimation = keyframes`
    0%, 100% {
      transform: scale(1);
      filter: blur(1px);
      opacity: 0.8;
    }
    50% {
      transform: scale(1.1);
      filter: blur(2px);
      opacity: 0.8;
    }
`;

export const DropzonePaper = styled(Paper, {
    shouldForwardProp: prop => !['isDragging', 'inDropZone', 'isDisabled', 'isHovering'].includes(prop as string),
})<{ isDragging: boolean; inDropZone: boolean; isDisabled: boolean; isHovering: boolean }>(
    ({ theme, isDragging, inDropZone, isDisabled, isHovering }) => ({
        position: 'relative',
        padding: theme.spacing(2),
        border: '1px dashed rgba(0, 0, 0, 0.12)',
        borderRadius: theme.shape.borderRadius,
        cursor: 'pointer',
        color: theme.palette.action.active,
        backgroundColor: 'rgba(0, 0, 0, 0.02)',
        textAlign: 'center',
        transition: 'all 0.3s ease',
        opacity: 1,

        '& h6': {
            color: isDisabled ? theme.palette.action.disabled : 'inherit',
            fontWeight: 600,
            transition: 'all 0.3s ease',
        },

        ...(isHovering && {
            borderColor: `${theme.palette.secondary.main}80`,
            backgroundColor: `${theme.palette.secondary.main}10`,
        }),

        ...(isDragging && {
            backgroundImage: 'repeating-linear-gradient(45deg, #f0f0f0, #f0f0f0 10px, #e0e0e0 10px, #e0e0e0 20px)',
        }),

        ...(inDropZone && {
            borderColor: theme.palette.secondary.main,
            backgroundImage: `repeating-linear-gradient(45deg,
                ${theme.palette.secondary.main}20,
                ${theme.palette.secondary.main}20 10px,
                ${theme.palette.secondary.main}40 10px,
                ${theme.palette.secondary.main}40 20px)`,
            '& h6': {
                color: theme.palette.secondary.main,
            },
        }),

        ...(isDisabled && {
            cursor: 'not-allowed',
            backgroundColor: theme.palette.action.disabledBackground,
            backgroundImage: 'none',
            '*': {
                color: theme.palette.action.disabled,
                animation: 'none',
                filter: 'grayscale(100%)',
                opacity: theme.palette.action.disabled,
            },
        }),
    }),
);

export const DropzoneLabel = styled(Box, {
    shouldForwardProp: prop => prop !== 'isDragging',
})<{ isDragging: boolean }>(({ isDragging }) => ({
    display: 'inline-flex',
    alignItems: 'center',
    overflow: 'hidden',
    '& span': {
        display: 'flex',
        maxWidth: isDragging ? 0 : 100,
        overflow: 'hidden',
        opacity: isDragging ? 0 : 1,
        transform: isDragging ? 'scale(0)' : 'scale(1)',
        transition: 'all 0.3s ease-in-out',
    },
}));

export const CloudContainer = styled(Box)({
    position: 'relative',
    display: 'inline-block',
    marginBottom: 8,
});

export const AnimatedShadow = styled(Box, {
    shouldForwardProp: prop => prop !== 'isAnimating',
})<{ isAnimating: boolean }>(({ isAnimating }) => ({
    position: 'absolute',
    bottom: 1,
    left: 0,
    right: 0,
    width: '90%',
    height: 10,
    margin: '0 auto',
    backgroundColor: 'rgba(0, 0, 0, 0.2)',
    borderRadius: '50%',
    scale: 1,
    filter: 'blur(1px)',
    opacity: 0.8,
    animation: isAnimating ? `${bobShadowAnimation} 2s ease-in-out infinite` : 'none',
}));

export const AnimatedCloudUpload = styled(CloudUpload, {
    shouldForwardProp: prop => prop !== 'isAnimating' && prop !== 'isDisabled',
})<{ isAnimating: boolean; isDisabled: boolean }>(({ isAnimating, isDisabled, theme }) => ({
    fontSize: 48,
    color: isDisabled ? theme.palette.action.disabled : theme.palette.secondary.main,
    transition: 'all 0.3s ease',
    animation: isAnimating ? `${bobAnimation} 2s ease-in-out infinite` : 'none',
    filter: isAnimating ? 'grayscale(0%)' : 'grayscale(100%)',
    opacity: isAnimating ? 1 : 0.5,
}));

export const ChipContainer = styled(Box)(({ theme }) => ({
    marginTop: theme.spacing(1),
    display: 'inline-grid',
    gap: theme.spacing(0.5),
    gridAutoFlow: 'column',
    alignItems: 'center',
    opacity: 0.8,
    '& .MuiChip-sizeSmall': {
        height: '70%',
        '.MuiChip-labelSmall': {
            padding: theme.spacing(0, 0.5),
            color: theme.palette.text.secondary,
            fontWeight: theme.typography.fontWeightLight,
        },
    },
}));
