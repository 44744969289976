import React from 'react';
import { List, ListItem, Box, Typography, IconButton, Button, CircularProgress } from '@mui/material';
import { CheckCircle, Error, Close } from '@mui/icons-material';
import { type ImportedFileState } from 'FrontRoyalMaterialUiForm';

interface FileListProps {
    files: ImportedFileState[];
    onFileRemove: (fileId: string) => void;
    onClearFiles: () => void;
    multiple: boolean;
}

export const FileList: React.FC<FileListProps> = ({ files, onFileRemove, onClearFiles, multiple }) => {
    if (files.length === 0) return null;

    return (
        <>
            <Box sx={{ display: 'flex', justifyContent: 'flex-end', mt: 1, mb: 0 }}>
                <Button onClick={onClearFiles} size="small">
                    Clear all
                </Button>
            </Box>
            <List data-testid="file-list">
                {files.map(file => (
                    <ListItem
                        key={file.id}
                        sx={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            alignItems: 'center',
                            backgroundColor: file.status === 'error' ? 'rgba(255, 0, 0, 0.05)' : 'transparent',
                            border: file.status === 'error' ? '1px solid rgba(255, 0, 0, 0.2)' : '1px solid #ccc',
                            borderRadius: 1,
                            mb: 1,
                        }}
                        data-testid={`file-list-item-${file.id}`}
                    >
                        <Box sx={{ display: 'flex', alignItems: 'center' }}>
                            {file.status === 'processing' ? (
                                <CircularProgress size={20} sx={{ mr: 1 }} />
                            ) : file.status === 'success' ? (
                                <CheckCircle sx={{ color: 'green', mr: 1 }} />
                            ) : (
                                <Error sx={{ color: 'red', mr: 1 }} />
                            )}
                            <Typography variant="body2">{file.file.name}</Typography>
                        </Box>
                        <Box sx={{ display: 'flex', alignItems: 'center' }}>
                            <Typography variant="caption" color="textSecondary" sx={{ mr: 1 }}>
                                {file.message}
                            </Typography>
                            {(multiple || files.length === 1) && (
                                <IconButton onClick={() => onFileRemove(file.id)} size="small">
                                    <Close />
                                </IconButton>
                            )}
                        </Box>
                    </ListItem>
                ))}
            </List>
        </>
    );
};
