import { type BrandConfig } from 'AppBranding';
import clsx from 'clsx';
import { useCurrentUser, useTargetBrandConfig } from 'FrontRoyalAngular';
import { type FC } from 'react';
import { useTranslation } from 'react-i18next';
import { type AssessmentBasedRefundEntitlementMetadata } from 'RefundEntitlement';
import { getCohort } from 'Users';

type CompletionInfo = { required: number; completed: number };

type Props = {
    exams: CompletionInfo;
    projects: CompletionInfo;
    capstone: CompletionInfo;
    smartcases: CompletionInfo;
    refundLevelAssessmentRanges: AssessmentBasedRefundEntitlementMetadata<true>['refundLevelAssessmentRanges'];
};

type PresentationProps = Props & {
    brandConfig: BrandConfig;
};

const classes = {
    container: clsx([
        'tw-text-xs',
        'tw-text-beige-beyond-dark',
        'tw-flex',
        'tw-flex-col',
        'tw-min-h-[270px]',
        'tw-justify-between',
        'tw-px-3',
        'xxs:tw-px-0',
    ]),
    label: clsx(['tw-text-lg', 'tw-mt-8', 'tw-mb-6']),
    courseWork: {
        container: clsx(['tw-my-5']),
        list: clsx(['tw-list-disc', 'tw-ml-[15px]']),
    },
    refundLink: {
        quantic: clsx(['hover:tw-underline', 'tw-text-coral']),
        valar: clsx(['hover:tw-underline', 'tw-text-blue']),
    },
    thresholds: {
        container: clsx(['tw-my-5']),
        heading: clsx(['tw-text-md', 'tw-text-black', 'tw-mb-6']),
        table: clsx(['tw-max-w-[350px]', 'tw-w-full', 'tw-mb-4']),
        tableHeader: clsx(['tw-font-semibold', 'tw-border', 'tw-border-beige-beyond-dark', 'tw-py-2 tw-px-4']),
        tableCell: clsx(['tw-border', 'tw-border-beige-beyond-dark', 'tw-py-2', 'tw-px-4']),
    },
};

const RefundPolicyLink: FC<{ brandConfig: BrandConfig }> = ({ brandConfig }) => {
    const { t } = useTranslation('back_royal');

    return (
        <a
            className={classes.refundLink[brandConfig.branding as keyof typeof classes.refundLink]}
            href={brandConfig.round62RefundPolicyUrl!}
            target="_blank"
            rel="noreferrer"
        >
            {t('grades.grades.refundPolicy')}
        </a>
    );
};

export const RefundEligibility: FC<Props> = props => {
    const currentUser = useCurrentUser();
    const brandConfig = useTargetBrandConfig();
    const cohort = getCohort(currentUser);

    if (!cohort) return null;
    // Aug 12 2024 - round 62 start date
    const isRound62Cohort = new Date(cohort.startDate * 1000).valueOf() >= new Date(1723449600000).valueOf();
    if (!isRound62Cohort) return null;

    return <RefundEligibilityPresentation brandConfig={brandConfig} {...props} />;
};

export function RefundEligibilityPresentation({
    exams,
    projects,
    capstone,
    smartcases,
    refundLevelAssessmentRanges,
    brandConfig,
}: PresentationProps) {
    const { t } = useTranslation('back_royal');

    const [totalCompleted, totalRequired] = Object.entries({ exams, projects, capstone, smartcases }).reduce<
        [number, number]
    >((p, n) => [p[0] + n[1].completed, p[1] + n[1].required], [0, 0]);

    return (
        <div className={classes.container}>
            <h2 className={classes.label}>{t('grades.grades.refundEligibility')}</h2>
            <p>
                {t('grades.grades.refund1')}
                <RefundPolicyLink brandConfig={brandConfig} /> {t('grades.grades.refund2')}
            </p>
            <div className={classes.courseWork.container}>
                <p>{t('grades.grades.sinceBeginning')}</p>
                <ul className={classes.courseWork.list}>
                    <li className="tw-lowercase">
                        {`${exams.completed}${t('grades.grades.of')}${exams.required} ${t('grades.grades.exams')}`}
                    </li>
                    <li>
                        {`${projects.completed}${t('grades.grades.of')}${projects.required} ${t(
                            'grades.grades.projects',
                        )}`}
                    </li>
                    {!!capstone.required && (
                        <li>
                            {`${capstone.completed}${t('grades.grades.of')}${capstone.required} capstone ${t(
                                'grades.grades.projects',
                            )}`}
                        </li>
                    )}
                    <li>{`${smartcases.completed}${t('grades.grades.of')}${smartcases.required} SMARTCASEs`}</li>
                    <li>
                        {`${t('grades.grades.total')} ${totalCompleted}${t('grades.grades.of')}${totalRequired} ${t(
                            'grades.grades.requiredAssessments',
                        )}`}
                    </li>
                </ul>
            </div>
            <p>
                {t('grades.grades.note1')}
                <RefundPolicyLink brandConfig={brandConfig} /> {t('grades.grades.note2')}
            </p>
            {refundLevelAssessmentRanges && (
                <div className={classes.thresholds.container}>
                    <h3 className={classes.thresholds.heading}>{t('grades.grades.refund_eligibility_thresholds')}</h3>
                    <table className={classes.thresholds.table}>
                        <thead>
                            <tr>
                                <th className={classes.thresholds.tableHeader}>
                                    {t('grades.grades.table_heading_assessment_range')}
                                </th>
                                <th className={classes.thresholds.tableHeader}>
                                    {t('grades.grades.table_heading_percent_refunded')}
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            {Object.keys(refundLevelAssessmentRanges)
                                .sort((k1, k2) => (parseInt(k1, 10) > parseInt(k2, 10) ? -1 : 1))
                                .map(key => (
                                    <tr key={key}>
                                        <td className={classes.thresholds.tableCell}>
                                            {key === '0'
                                                ? `${refundLevelAssessmentRanges[key][0]}+`
                                                : t('grades.grades.assessment_range', {
                                                      from: refundLevelAssessmentRanges[key][0],
                                                      to: refundLevelAssessmentRanges[key][1],
                                                  })}
                                        </td>
                                        <td className={classes.thresholds.tableCell}>{`${parseInt(key, 10)}%`}</td>
                                    </tr>
                                ))}
                        </tbody>
                    </table>
                    <p>{t('grades.grades.thresholds_footer_note')}</p>
                </div>
            )}
        </div>
    );
}
