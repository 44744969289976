import { useEffect, useState } from 'react';
import { Alert, Snackbar, type SnackbarProps, type AlertProps, type SnackbarCloseReason } from '@mui/material';

interface SnackbarAlertProps {
    open: boolean;
    message?: React.ReactNode;
    severity?: 'error' | 'warning' | 'info' | 'success';
    variant?: 'filled' | 'outlined';
    duration?: number;
    onClose?: (event?: Event | React.SyntheticEvent<Element, Event>, reason?: SnackbarCloseReason) => void;
    snackbarProps?: SnackbarProps;
    alertProps?: AlertProps;
}

export const buildMessageLines = (messages: string[]) => messages.map(message => <div key={message}>{message}</div>);

export const SnackbarAlert = ({
    open,
    message,
    severity,
    variant = 'filled',
    duration,
    onClose,
    snackbarProps,
    alertProps,
}: SnackbarAlertProps) => {
    const [_open, setOpen] = useState(open);
    const [_message, setMessage] = useState(message);
    const [_severity, setSeverity] = useState(severity);

    useEffect(() => {
        setOpen(open);
    }, [open]);

    useEffect(() => {
        // To prevent ugly transitions when the message is emptied, we buffer the message
        // change until the snackbar is closed
        if (!message) {
            setOpen(false);
            setTimeout(() => {
                setMessage('');
                setSeverity(severity);
            }, 300);
        } else {
            setMessage(message);
            setSeverity(severity);
        }
    }, [message, severity]);

    const handleClose = (event?: Event | React.SyntheticEvent<Element, Event>, reason?: SnackbarCloseReason) => {
        if (reason === 'clickaway') {
            return;
        }
        onClose?.(event, reason);
        setOpen(false);
    };

    const snackbarSx = {
        ...(snackbarProps?.sx || {}),
        bottom: { xs: 64, sm: 24 },
        right: { xs: 16, sm: 24 },
        left: { xs: 'auto' },
        opacity: 0.9,
    };

    const alertSx = {
        transition: 'all 0.3s',
        ...(alertProps?.sx || {}),
        ...(variant === 'outlined' && { bgcolor: 'background.paper' }),
    };
    return (
        <Snackbar open={_open} autoHideDuration={duration} onClose={handleClose} sx={snackbarSx} {...snackbarProps}>
            <Alert onClose={handleClose} severity={_severity} variant={variant} sx={alertSx} {...alertProps}>
                {_message}
            </Alert>
        </Snackbar>
    );
};
