import {
    Radio as MuiRadio,
    FormControl,
    FormControlLabel,
    useTheme,
    type RadioProps as MuiRadioProps,
    type FormControlLabelProps,
} from '@mui/material';
import { useFormContext, Controller } from 'FrontRoyalReactHookForm';
import { memo, type CSSProperties, type ChangeEvent } from 'react';

type RadioProps = MuiRadioProps & {
    label?: string;
    name: string;
    value: string | number;
    style?: CSSProperties;
    labelPlacement: FormControlLabelProps['labelPlacement'];
    classes: FormControlLabelProps['classes'];
};

const RadioComponent = ({
    label,
    name,
    value: valueWhenChecked,
    style = {},
    labelPlacement = 'end',
    classes = {},
    ...rest
}: RadioProps) => {
    const { control, watch, setFieldValue } = useFormContext();
    const { palette } = useTheme();

    // We have to do `|| false` instead of `watch(name, false)` because `watch(name, false)` sometimes returns
    // `undefined` and that leads to errors about switching between controlled and uncontrolled components.
    const checked = watch(name) !== undefined && watch(name) === valueWhenChecked;
    const onChange = (event: ChangeEvent<HTMLInputElement>) => {
        if (event.currentTarget.checked) {
            setFieldValue(name, valueWhenChecked);
        } else {
            setFieldValue(name, undefined);
        }
    };

    return (
        <Controller
            name={name}
            control={control}
            render={() => (
                <FormControl>
                    <FormControlLabel
                        control={<MuiRadio onChange={onChange} {...rest} checked={checked} />}
                        style={{ color: palette?.text.primary, display: 'flex', ...style }}
                        label={label}
                        labelPlacement={labelPlacement}
                        classes={classes}
                    />
                </FormControl>
            )}
        />
    );
};

const compareFn = (prev: RadioProps, next: RadioProps) =>
    prev.checked === next.checked && prev.label === next.label && prev.disabled === next.disabled;

export const Radio = memo(RadioComponent, compareFn) as typeof RadioComponent;

export default Radio;
