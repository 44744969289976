import { type Cohort } from 'Cohorts';
import { formatScore as _formatScore } from 'FormatScore';
import { type StreamProgressRecord, type LessonProgressRecord, type ProjectProgressRecord } from 'FrontRoyalStore';

const PassingScores = {
    capstone: 3,
    standard: 2,
    presentation: 2,
} as const;

const getPassingProjectScore = (cohort: Cohort, projectType: string) => {
    if (
        projectType === 'standard' &&
        cohort &&
        ((cohort.programType === 'emba' && new Date(cohort.startDate * 1000) <= new Date('2019/07/01')) ||
            (cohort.programType === 'mba' && new Date(cohort.startDate * 1000) <= new Date('2019/07/29')))
    ) {
        return 1;
    }

    return PassingScores[projectType as keyof typeof PassingScores];
};

export const getProjectStatus = (progress: ProjectProgressRecord | undefined, projectType: string, cohort: Cohort) => {
    const passingScore = getPassingProjectScore(cohort, projectType);

    if (!progress) return '\u2014';

    if (progress.marked_as_passed || progress.waived) return 'P';

    if (progress.score === null || progress.score === undefined) return '\u2014';

    return progress.score >= passingScore ? 'P' : 'F';
};

export const formatScore = (progress: LessonProgressRecord | StreamProgressRecord | undefined) => {
    if (!progress || !progress.complete) return '\u2014';

    let score: number | string | null = null;
    if ('best_score' in progress) {
        score = progress.best_score;
    } else if ('official_test_score' in progress) {
        score = progress.official_test_score ?? null;
    } else if ('waiver' in progress && progress.waiver) {
        score = progress.waiver;
    }

    if (typeof score !== 'number') return 'P';

    return `${_formatScore(score)}%`;
};
