import { useCallback } from 'react';
import { Controller, useFormContext } from 'FrontRoyalReactHookForm';
import { useGooglePlaces, type Place, type PlaceDetails } from 'GooglePlaces';
import { TextField, Chip } from '@mui/material';
import { uniqWith, isEqual } from 'lodash/fp';
import { locationString } from 'Location';

type MultiLocationInputProps = { name: string; fullWidth?: boolean };

export const MultiLocationInput = ({ name, fullWidth }: MultiLocationInputProps) => {
    const { control, watch, setFieldValue } = useFormContext<{ [name: string]: PlaceDetails[] }>();
    const value = watch(name);

    const onPlaceChanged = useCallback(
        (_place: Place, placeDetails: PlaceDetails, input: HTMLInputElement) => {
            setFieldValue(name, uniqWith(isEqual, [...value, placeDetails]));
            input.value = '';
        },
        [setFieldValue, value, name],
    );

    const locationInputRef = useGooglePlaces({ autoCompleteOptions: {}, onPlaceChanged });

    const removePlace = useCallback(
        (details: PlaceDetails) => () =>
            setFieldValue(
                name,
                value.filter(val => !isEqual(val, details)),
            ),
        [setFieldValue, value, name],
    );

    return (
        <Controller
            name={name}
            control={control}
            render={() => (
                <>
                    <TextField
                        name="placesInput"
                        label="Location"
                        placeholder="Enter a location"
                        variant="outlined"
                        fullWidth={fullWidth}
                        ref={locationInputRef}
                    />
                    <div style={{ paddingLeft: '8px' }}>
                        {value.map(placeDetails => (
                            <Chip
                                key={locationString(placeDetails)}
                                label={locationString(placeDetails)}
                                onDelete={removePlace(placeDetails)}
                                variant="outlined"
                                color="primary"
                            />
                        ))}
                    </div>
                </>
            )}
        />
    );
};

export default MultiLocationInput;
