import { useCallback } from 'react';
import { useAngularContext } from 'AngularContext';
import { useGooglePlaces, type Place, type PlaceDetails } from 'GooglePlaces';
import { useAMapAutoComplete } from 'AMap';
import { TextField, type TextFieldProps } from '@mui/material';
import { locationString, formattedAddress } from 'Location';
import { Controller, useFormContext } from 'FrontRoyalReactHookForm';

type MapConfig = { poi_type_city: unknown };

type SingleLocationInputProps = {
    name: string;
    placeType: string;
} & TextFieldProps;

const locationInputOptions = (placeType: string, mapConfig: MapConfig) => {
    if (placeType === 'address') {
        return {
            aMapAutoCompleteOptions: {
                type: null,
            },
            googlePlaceOptions: {
                types: null,
            },
            formatter: formattedAddress,
        };
    }
    return {
        aMapAutoCompleteOptions: {
            type: mapConfig?.poi_type_city,
        },
        googlePlaceOptions: {
            types: ['(cities)'],
        },
        formatter: locationString,
    };
};

type LocationInputValue = { id: string; details: PlaceDetails };

export const SingleLocationInput = ({ name, placeType, ...rest }: SingleLocationInputProps) => {
    const { control, setFieldValue, watch } = useFormContext<{ [name: string]: LocationInputValue }>();
    const value = watch(name);

    const $injector = useAngularContext();

    const ConfigFactory = $injector.get('ConfigFactory') as {
        getSync: (arg: boolean) => { chinaRegionMode: () => boolean };
    };
    const chinaRegionMode = ConfigFactory.getSync(true).chinaRegionMode();
    const mapConfig = $injector.get('AMAP_CONFIG') as MapConfig;

    const onPlaceChanged = useCallback(
        ({ place_id }: Place, placeDetails: PlaceDetails) => {
            setFieldValue(name, {
                id: place_id,
                details: placeDetails,
            });
        },
        [setFieldValue, name],
    );

    const { aMapAutoCompleteOptions, googlePlaceOptions, formatter } = locationInputOptions(placeType, mapConfig);

    const locationInputRef = useAMapAutoComplete({
        autoCompleteOptions: aMapAutoCompleteOptions,
        onPlaceChanged,
    });

    const googleLocationInputRef = useGooglePlaces({
        autoCompleteOptions: googlePlaceOptions,
        onPlaceChanged,
    });

    return (
        <Controller
            control={control}
            name={name}
            render={({ field }) => (
                <div>
                    <TextField
                        name="placesInput"
                        defaultValue={value ? formatter(value.details) : ''}
                        {...rest}
                        inputProps={{
                            onBlur: field.onBlur,
                        }}
                        ref={chinaRegionMode ? locationInputRef : googleLocationInputRef}
                        variant="outlined"
                    />
                </div>
            )}
        />
    );
};

export default SingleLocationInput;
