import { useState, useCallback, useEffect } from 'react';
import { type ImportedFileState } from '../FrontRoyalMaterialUiForm.types';

export const useFileProcessing = (
    initialFiles: ImportedFileState[],
    multiple: boolean,
    accept: string[],
    onFileAdded?: (file: ImportedFileState) => Promise<ImportedFileState> | ImportedFileState,
    onFileRemoved?: (fileId: string) => void,
    onClearFiles?: () => void,
) => {
    const [files, setFiles] = useState<ImportedFileState[]>(initialFiles);
    const isDisabled = !multiple && files.length >= 1;

    useEffect(() => {
        setFiles(initialFiles);
    }, [initialFiles]);

    const isFileIdUnique = useCallback((fileId: string) => !files.some(file => file.id === fileId), [files]);

    const generateFileId = useCallback(
        (file: File) => {
            let fileId = `${file.name}-${file.lastModified}`;
            // Ensure the file ID is unique, appending an index if necessary
            let index = 1;
            while (!isFileIdUnique(fileId)) {
                fileId = `${file.name}-${file.lastModified}-${index}`;
                index += 1;
            }
            return fileId;
        },
        [isFileIdUnique],
    );

    const isFileTypeAccepted = useCallback(
        (file: File) => accept.some(type => file.name.toLowerCase().endsWith(type.toLowerCase())),
        [accept],
    );

    const processFile = useCallback(
        async (file: File): Promise<ImportedFileState | null> => {
            if (isDisabled) return null;

            if (!isFileTypeAccepted(file)) {
                return {
                    id: generateFileId(file),
                    file,
                    status: 'error',
                    message: 'Unsupported file type',
                };
            }

            const newFile: ImportedFileState = {
                id: generateFileId(file),
                file,
                status: 'success',
                message: `${(file.size / (1024 * 1024)).toFixed(2)} MB`,
            };

            if (!onFileAdded) return newFile;

            try {
                const result = await Promise.resolve(onFileAdded(newFile));
                return { ...newFile, ...result };
            } catch (error) {
                return {
                    ...newFile,
                    status: 'error',
                    message: 'Error processing file',
                };
            }
        },
        [isFileTypeAccepted, generateFileId, onFileAdded, isDisabled],
    );

    const handleNewFiles = useCallback(
        async (newFiles: File[]) => {
            const processedFiles = await Promise.all(newFiles.map(processFile));
            const validFiles = processedFiles.filter((file): file is ImportedFileState => file !== null);
            setFiles(prevFiles => (multiple ? [...prevFiles, ...validFiles] : validFiles.slice(0, 1)));
        },
        [processFile, multiple],
    );

    const handleDrop = useCallback(
        async (event: DragEvent) => {
            event.preventDefault();
            event.stopPropagation();
            if (isDisabled) return;

            const droppedFiles = Array.from(event.dataTransfer?.files || []);
            handleNewFiles(droppedFiles);
        },
        [handleNewFiles, isDisabled],
    );

    const handleFileInput = useCallback(
        async (event: React.ChangeEvent<HTMLInputElement>) => {
            if (isDisabled) return;

            const selectedFiles = Array.from(event.target.files || []);
            handleNewFiles(selectedFiles);
        },
        [handleNewFiles, isDisabled],
    );

    const handleFileRemove = useCallback(
        (fileId: string) => {
            setFiles(prevFiles => prevFiles.filter(f => f.id !== fileId));
            onFileRemoved?.(fileId);
        },
        [onFileRemoved],
    );

    const handleClearFiles = useCallback(() => {
        setFiles([]);
        onClearFiles?.();
    }, [onClearFiles]);

    return {
        files,
        isDisabled,
        handleDrop,
        handleFileInput,
        handleFileRemove,
        handleClearFiles,
    };
};
