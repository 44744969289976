import { useFormContext, Controller } from 'FrontRoyalReactHookForm';
import { generateGuid } from 'guid';
import { TextField as MuiTextField, type StandardTextFieldProps } from '@mui/material';

type Props = StandardTextFieldProps & {
    name: string;
};

export const TextField = ({ name, inputProps, InputProps, defaultValue = '', ...rest }: Props) => {
    const { control, getFieldState } = useFormContext();
    const { error, isTouched } = getFieldState(name);

    const updatedComponentInputProps: typeof InputProps = {
        error: isTouched && !!error,
        ...InputProps,
    };

    const updatedHtmlInputProps: typeof inputProps = {
        ...inputProps,
        style: {
            ...inputProps?.style,
            resize: rest?.multiline ? 'vertical' : undefined,
        },
    };
    return (
        <Controller
            control={control}
            name={name}
            defaultValue={defaultValue}
            render={({ field }) => (
                <MuiTextField
                    id={generateGuid()}
                    {...field}
                    value={field.value || ''}
                    {...rest}
                    inputProps={updatedHtmlInputProps}
                    InputProps={updatedComponentInputProps}
                />
            )}
        />
    );
};

export default TextField;
