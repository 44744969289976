import { useRef, type ReactNode, type Dispatch, type SetStateAction } from 'react';
import { IconButton, Tooltip, Popover, type PopoverOrigin } from '@mui/material';

type MaterialUiPopoverButtonProps = {
    title: NonNullable<ReactNode>;
    label: string;
    children: ReactNode;
    icon: ReactNode;
    open: boolean;
    setOpen: Dispatch<SetStateAction<boolean>>;
    anchorOrigin?: PopoverOrigin;
    transformOrigin?: PopoverOrigin;
};

const defaultAnchorOrigin = {
    vertical: 'top',
    horizontal: 'right',
} as PopoverOrigin;

const defaultTransformOrigin = {
    vertical: 'top',
    horizontal: 'left',
} as PopoverOrigin;

export const MaterialUiPopoverButton = ({
    title,
    label,
    children,
    icon,
    open,
    setOpen,
    anchorOrigin = defaultAnchorOrigin,
    transformOrigin = defaultTransformOrigin,
}: MaterialUiPopoverButtonProps) => {
    const buttonRef = useRef(null);

    return (
        <>
            <Tooltip title={title}>
                <IconButton ref={buttonRef} aria-label={label} onClick={() => setOpen(!open)}>
                    {icon}
                </IconButton>
            </Tooltip>
            <Popover
                open={open}
                anchorEl={buttonRef.current}
                onClose={() => setOpen(false)}
                anchorOrigin={anchorOrigin}
                transformOrigin={transformOrigin}
            >
                {children}
            </Popover>
        </>
    );
};

export default MaterialUiPopoverButton;
