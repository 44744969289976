import { type ChangeEvent } from 'react';
import { Button, InputLabel, FormControl, TextField } from '@mui/material';
import { CloudUpload } from '@mui/icons-material';
import { useFormContext, Controller } from 'FrontRoyalReactHookForm';

type FileInputProps = { name: string; label: string };

const changeHandler = ({ target: { files } }: ChangeEvent<HTMLInputElement>) => {
    const file = files?.[0];
    return { getUrl: () => file && window.URL.createObjectURL(file), file };
};

export const FileInput = ({ name, label }: FileInputProps) => {
    const { control } = useFormContext();

    return (
        <Controller
            name={name}
            control={control}
            render={({ field }) => (
                <FormControl>
                    <InputLabel shrink style={{ marginLeft: '-14px' }}>
                        {label}
                    </InputLabel>
                    <div>
                        <Button variant="inputButton">
                            <CloudUpload />
                            <TextField
                                type="file"
                                onChange={(e: ChangeEvent<HTMLInputElement>) => field.onChange(changeHandler(e))}
                                style={{
                                    opacity: 0,
                                    position: 'absolute',
                                    left: '0px',
                                    top: '0px',
                                    height: '100%',
                                    width: '100%',
                                }}
                            />
                        </Button>
                    </div>
                </FormControl>
            )}
        />
    );
};

export default FileInput;
