import { createTheme, type PaletteColorOptions } from '@mui/material';
import { grey } from '@mui/material/colors';
import Oreo from 'Oreo';

declare module '@mui/material/Button' {
    interface ButtonPropsColorOverrides {
        neutral: true;
    }
    interface ButtonPropsVariantOverrides {
        inputButton: true;
    }
}

declare module '@mui/material/styles' {
    interface Palette {
        neutral?: PaletteColorOptions;
    }
    interface PaletteOptions {
        neutral?: PaletteColorOptions;
    }
}

export const defaultTheme = createTheme({
    typography: {
        fontFamily: 'ProximaNovaSoft',
    },

    palette: {
        primary: {
            light: Oreo.COLOR_V3_BLUE_LIGHT,
            main: Oreo.COLOR_V3_BLUE,
            dark: Oreo.COLOR_V3_BLUE_DARK,
            contrastText: Oreo.COLOR_V3_WHITE,
        },

        secondary: {
            light: Oreo.COLOR_V3_CORAL,
            main: Oreo.COLOR_V3_CORAL,
            dark: Oreo.COLOR_V3_CORAL_DARK,
            contrastText: Oreo.COLOR_V3_WHITE,
        },

        neutral: {
            light: grey[200],
            main: grey[300],
            dark: grey[400],
            contrastText: Oreo.COLOR_V3_BLACK,
        },

        error: {
            main: Oreo.COLOR_V3_RED,
        },

        text: {
            primary: '#000000',
        },
    },

    breakpoints: {
        values: {
            xs: 0,
            sm: 768,
            md: 992,
            lg: 1200,
            xl: 1920,
        },
    },
});

export const theme = createTheme(defaultTheme, {
    components: {
        MuiFormLabel: {
            styleOverrides: {
                asterisk: { color: Oreo.COLOR_V3_RED },
            },
        },

        MuiButton: {
            variants: [
                {
                    props: { variant: 'inputButton' },
                    style: {
                        backgroundColor: grey['300'],
                        '&:hover': {
                            backgroundColor: grey['200'],
                        },
                        boxShadow: defaultTheme.shadows[1],
                        marginTop: defaultTheme.spacing(1),
                        marginBottom: defaultTheme.spacing(1),
                    },
                },
            ],
        },
    },
});

export default theme;
