import { type ReactNode, type CSSProperties } from 'react';
import { Box } from '@mui/material';

type FlexProps = {
    children: ReactNode;
    display?: 'flex' | 'inline-flex';
    flexDirection?: CSSProperties['flexDirection'];
    flex?: CSSProperties['flex'];
    flexBasis?: CSSProperties['flexBasis'];
    flexGrow?: CSSProperties['flexGrow'];
    flexShrink?: CSSProperties['flexShrink'];
    alignItems?: CSSProperties['alignItems'];
    alignContent?: CSSProperties['alignContent'];
    justifyItems?: CSSProperties['justifyItems'];
    justifyContent?: CSSProperties['justifyContent'];
    flexWrap?: CSSProperties['flexWrap'];
    style?: CSSProperties;
    id?: string;
    className?: string;
};

export const Flex = ({ children, style, id, className, ...rest }: FlexProps) => (
    <Box sx={{ display: 'flex', width: '100%', ...rest }} style={style} id={id} className={className}>
        {children}
    </Box>
);

export default Flex;
