/* eslint-disable react/no-array-index-key */
import clsx from 'clsx';
import { type FC } from 'react';
import { type RowData, type Section } from './Grades.types';
import { Row } from './Row';

type Props = {
    sections: Section[];
};

const classes = {
    container: clsx(['tw-px-3.5', 'tw-pb-5', 'tw-pt-3.5']),
    section: {
        container: clsx(['tw-mb-4', 'last:tw-mb-0', 'tw-text-black']),
        header: {
            desktop: {
                h4: clsx(['tw-hidden', 'sm:tw-block', 'tw-text-[14px]']),
                row: clsx(['tw-border-none', 'tw-min-h-0', 'tw-font-semibold']),
            },
            mobile: clsx([
                'tw-text-[14px]',
                'tw-font-semibold',
                'tw-mb-3',
                'tw-flex',
                'tw-justify-center',
                'sm:tw-hidden',
            ]),
        },
    },
};

export const PlaylistBreakdown: FC<Props> = ({ sections }) => (
    <div className={classes.container}>
        {sections.map(
            ({ title, rowsData }) =>
                !!rowsData?.length && (
                    <div className={classes.section.container} key={title}>
                        {sections.length > 1 && <h4 className={classes.section.header.mobile}>{title}</h4>}
                        <h4 className={classes.section.header.desktop.h4}>
                            <Row
                                rowData={{
                                    started: true,
                                    rowTextList: rowsData?.[0]?.rowTextList.map(([l, v]) => [
                                        v,
                                        l,
                                    ]) as RowData['rowTextList'],
                                }}
                                desktopClassName={classes.section.header.desktop.row}
                            />
                        </h4>
                        {rowsData?.map((rowData, i) => (
                            <Row rowData={rowData} key={i} />
                        ))}
                    </div>
                ),
        )}
    </div>
);
